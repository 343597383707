// all const var must prefix with 'key' which is not direct action type but will
// be used for some library to generate action automatically such as
// redux-actions.

// common key for async call that do not need any reducer handling and it is put
// under errand action creator, so just use and no need to put under any other
// place.
export const keyC3Common = '@@C3Common';

// call
export const keyChangeAcceptCall = "ChangeAcceptCall";
export const keyGetAcceptCall = "GetAcceptCall";
export const keyGetOutboundPhones = "GetOutboundPhones";
export const keyGetAllSipAgents = "GetAllSipAgents";
export const keyGetActiveCalls = "GetActiveCalls";
export const keyGetSipNumberInUse = "GetSipNumberInUse";

// workflow
export const keyAdminTagNameAll = 'AdminTagNameAll'
export const keyAdminTagSimpleList = 'AdminTagSimpleList';
export const keyAdminTagList = 'AdminTagList';
export const keyAgentAreas = 'AgentAreas';
export const keyAgentTemplates = 'AgentTemplates';

export const keyAgents = 'Agents';
export const keyAllConstants = 'AllConstants';
export const keyFolders = 'Folders';
export const keyAgentGroups = 'Groups';
export const keyAreas = 'Areas';
export const keyAreasAgentsData = 'keyAreasAgentsData';
export const keyOrganizationOverviewAreas = 'OrganizationOverviewAreas';
export const keyChannels = 'Channels';
export const keyCloseErrand = 'CloseErrand';
export const keyCloseErrands = 'CloseErrands';
export const keyConstants = 'Constants';
export const keyDeleteErrands = 'DeleteErrands';
export const keyBulkSendErrands = 'BulkSendErrands';
export const keyErrandAgentStatistics = 'ErrandAgentStatistics';
export const keyErrandFetchAreaErrandCount = 'ErrandFetchAreaErrandCount';
export const keyErrandList = 'ErrandList';
export const keyErrandListChat = 'ErrandListChat';
export const keyErrandTokenDecrypt = 'ErrandTokenDecrypt';
export const keyChatForwardArea = 'ChatForwardArea';
export const keyChatForwardAgent = 'ChatForwardAgent';
export const keyErrandPreview = 'ErrandPreview';
export const keyErrandWorkflowSettings = 'ErrandWorkflowSettings';
export const keyGetErrands = 'GetErrands';
export const keyQueueToMe = 'QueueToMe';
export const keyForwardToArea = 'ForwardToArea';
export const keyMoveToFolder = 'MoveToFolder';
export const keyForwardToAgent = 'ForwardToAgent';
export const keyRetunToInbox = 'RetunToInbox';
export const keyLinkErrand = 'LinkErrand';
export const keyWFExpertQueries = 'WFExpertQueries';
export const keyAgentSetErrandView = 'AgentSetErrandView';
export const keyGetClientAvatar = 'ClientAvatar';
export const keyGetSlaTime = "GetSlaTime";
export const keyConnectedAgentAreas = 'ConnectedAgentAreas';
export const keyStatisticsAgentAreas = 'StatisticsAgentAreas';

// errand
export const keyAcquireErrand = 'AcquireErrand';
export const keyAcquireOtherContacts = 'keyAcquireOtherContacts';
export const keyAssociatedAreaData = 'AssociatedAreaData';
export const keyAssociatedExtendedData = 'AssociatedExtendedData';
export const keyErrandNotesDeleteAttachment = 'ErrandNotesDeleteAttachment';
export const keyErrandNotesUploadAttachment = 'ErrandNotesUploadAttachment';
export const keyFetchClientAddress = 'FetchClientAddress';
export const keyFetchClientsAddressList = 'FetchClientsAddressList';
export const keyFetchErrandNotes = 'FetchErrandNotes';
export const keyFetchExtendedData = 'FetchExtendedData';
export const keyFetchExternalExpertList = 'FetchExternalExpertList';
export const keyFetchExternalExpertThread = 'FetchExternalExpertThread';
export const keyFetchExternalQueries = 'FetchExternalQueries';
export const keyFetchHistory = 'FetchHistory';
export const keyFetchTranslateDetect = 'FetchTranslateDetect';
export const keyEEAnswerSave = 'EEAnswerSave';
export const keyErrandAreaData = 'ErrandAreaData';
export const keyErrandChangeErrandArea = 'ErrandChangeErrandArea';
export const keyErrandChangeInternalState = 'ErrandChangeInternalState';
export const keyGetOneErrandInternalState = 'keyGetOneErrandInternalState';
export const keyErrandContacts = 'ErrandContacts';
export const keyErrandMyErrands = 'ErrandMyErrands';
export const keyFetchLinkedErrands = 'FetchErrandLinkedErrands';
export const keyErrandContactsHistories = 'ErrandContactsHistories';
export const keyCompanyContactHistories = 'CompanyContactHistories';
export const keyCompanyOtherContacts = 'CompanyOtherContacts';
export const keyErrandRemoveAllTempAttachment = 'ErrandRemoveAllTempAttachment';
export const keyErrandRemoveTemporaryAttachment = 'ErrandRemoveTemporaryAttachment';
export const keyErrandSuggestToLibrary = 'ErrandSuggestToLibrary';
export const keyErrandUploadAnswerAttachment = 'ErrandUploadAnswerAttachment';
export const keyExternalexpertEdit = 'ExternalexpertEdit';
export const keyLoadBasicErrand = 'LoadBasicErrand';
export const keyMultiThreadsHistory = 'MultiThreadsHistory';
export const keyOtherContactHistory = 'OtherContactHistory';
export const keyReloadOneErrand = 'keyReloadOneErrand';
export const keyTryGetOneBasicErrand = 'TryGetOneBasicErrand';
export const keyTurnExternalExpertLightOff = 'TurnExternalExpertLightOff';
export const keyLastErrandInThread = 'LastErrandInThread';
export const keyErrandPostponeDate = 'ErrandPostponeDate';
export const keyErrandId = 'ErrandId';
export const keyOpenErrandData = 'OpenErrandData';
export const keyErrandMinLoad = 'ErrandMinLoad';
export const keyVerifyChannelSelection = 'VerifyChannelSelection';
export const keyAcquireErrandOwnerStatus = 'AcquireErrandOwnerStatus';
export const keyAgentWorkingOnErrand = 'AgentWorkingOnErrand';
export const keyIncomingEmailErrands = 'keyIncomingEmailErrands';
export const keyAllActiveErrands = 'keyAllActiveErrands';
export const keyAllActiveAgents = 'keyAllActiveAgents';
export const keyErrandSuggestedAnswer = 'ErrandSuggestedAnswer';
export const keyAgentCallLog = "AgentCallLog"
export const keyCRMDataFetch = 'fetchCRMData';

// export const keyTurnLightOffWhenRead = 'TurnLightOffWhenRead';
export const keySendAnswer = 'SendAnswer';
export const keySendEE = 'SendEE';
export const keyUpdateAnswer = 'UpdateAnswer';
export const keyUpdateAnswerEE = 'UpdateAnswerEE';
export const keySavedEE = 'SavedEE';
export const keyReopenErrand = 'ReopenErrand';
export const keyResendAnswer = 'ResendAnswer';
export const keyPublishErrand = 'ResendAnswer';
export const keyUnpublishErrand = 'UnpublishErrand';
export const keyUpdateManualErrand = 'UpdateManualErrand'
export const keyDeleteOneErrandNotes = 'DeleteOneErrandNotes';
export const keyCreateOneErrandNotes = 'CreateOneErrandNotes';
export const keyUpdateOneErrandNotes = 'UpdateOneErrandNotes';
export const keyErrandTranslation = 'ErrandTranslation';
export const keyErrandMoveFolder = 'ErrandMoveFolder';
// export const keyErrandForwardToArea = 'ErrandForwardToArea';
export const keyGetAreaNotification = 'GetAreaNotification';
export const keyErrandPrintContent = 'ErrandPrintContent';
export const keyErrandPinToTop = 'ErrandPinToTop';
export const keyErrandUpdatePriority = 'ErrandUpdatePriority';
export const keyErrandUpdateLockToMe = 'ErrandUpdateLockToMe';
export const keySocialMediaUserProfile = 'SocialMediaUserProfile';
export const keySocialMediaPostsHistory = 'SocialMediaPostsHistory';
export const keySocialMediaPostActions = 'SocialMediaPostActions';
export const keyErrandFetchAreaTags = 'ErrandFetchAreaTags';
export const keyErrandFBRatings = 'ErrandFBRatings';
export const keyErrandUpdateDueDate = 'ErrandUpdateDueDate';
export const keySocialMediaPostsMessages = 'SocialMediaPostsMessages';
export const keySocialMediaCheckFriendship = 'SocialMediaCheckFriendship';
export const keySocialMediaPostsUpdateAns = 'SocialMediaPostsUpdateAns';
export const keyErrandAttachmentViaURL = 'keyErrandAttachmentViaURL';
export const keyGetKnowledgeBase = 'keyGetKnowledgeBase';
export const keyGetKnowledgeBaseCategory = 'keyGetKnowledgeBaseCategory';
export const keyGetKnowledgeBaseList = 'keyGetKnowledgeBaseList';
export const keyFeaturedQuestion = 'keyFeaturedQuestion';
export const keyGetContactCardEntry =  'keyGetContactCardEntry';
export const keyGetCustomerByAddress = 'keyGetCustomerByAddress';
export const keyAddEntryToContactCard = 'keyAddEntryToContactCard';
export const keyDeleteContactCardEntry = 'keyDeleteContactCardEntry';
export const keyGetCustomerNotes = 'keyGetCustomerNotes';
export const keyDeleteCustomerNote = 'keyDeleteCustomerNote';
export const keyPostCustomerNote = 'keyPostCustomerNote';
export const keyGetContactBook = 'keyGetContactBook';
export const keyGetErrandStatus = 'keyGetErrandStatus';
export const keyCustomerAvatar = 'keyCustomerAvatar';
export const keyRecordEditorSize = 'keyRecordEditorSize';
export const keyErrandFBEmotionlist = 'keyErrandFBEmotionlist';
export const keyGetAnnouncement = 'keyGetAnnouncement';
// chat
export const keyChangeAcceptInternalChat = 'ChangeAcceptInternalChat';
export const keyChangeFavouriteChatAgent = 'ChangeFavouriteChatAgent';
export const keyFavouriteChatAgent = 'FavouriteChatAgent';
export const keyAllChatAgent = 'AllChatAgent';
export const keyAgentAPIAgentlist = 'AgentAPIAgentlist';
export const keyAgentAPIAnswerinvite = 'AgentAPIAnswerinvite';
export const keyAgentAPIForward = 'AgentAPIForward';
export const keyAgentAPIGetAreas = 'AgentAPIGetAreas';
export const keyAgentAPIGetAgents = 'AgentAPIGetAgents';
export const keyAgentAPICanClose = 'AgentAPICanClose';
export const keyAgentAPIQueues = 'AgentAPIQueues';
export const keyAgentAPISessiondata = 'AgentAPISessiondata';
export const keyGetChatAreas = 'GetChatAreas';
export const keyForwardChat = 'ForwardChat';
export const keyGetChatAgents = 'GetChatAgents';
export const keyGetChatCandidates = 'GetChatCandidates';
export const keyGetAgentListInChat = 'keyGetAgentListInChat';
export const keyGetClosedChatErrand = 'keyGetClosedChatErrand';
export const keyGetAgentAPIChatStatus = 'keyGetAgentAPIChatStatus';

// admin chatbots
export const keyAdminChatbots = 'AdminChatbots';
export const keyAdminChatbotsQuery = 'AdminChatbotsQuery';
export const keyAdminEditChatbots = 'AdminEditChatbots';
export const keyAdminEditChatbotsIntents = 'AdminEditChatbotsIntents';
export const keyAdminDeleteChatbots = 'AdminDeleteChatbots';
export const keyAdminDeleteChatbotsIntents = 'AdminDeleteChatbotsIntents';
export const keyAdminGetChatbotsIntents = 'AdminGetChatbotsIntents';
export const keyAdminGetOneChatbot = 'AdminGetOneChatbot';
export const keyAdminSyncChatbot = 'AdminSyncChatbot';
export const keyEditChatbotsTrainingphrases = 'EditChatbotsTrainingphrases'

// admin receipts and greetings
export const keyAdminFilesAreaarchiveimages = 'AdminFilesAreaarchiveimages';
export const keyAdminReceiptgreetings = 'AdminReceiptgreetings';
export const keyAdminSaveReceiptgreetings = 'AdminSaveReceiptgreetings';
export const keyAdminDeleteReceiptgreetings = 'AdminDeleteReceiptgreetings';
export const keyAdminRGServiceaccounts = 'AdminRGServiceaccounts';

// admin SMTP
export const keyAdminSmtp = 'AdminSmtp';
export const keyAdminSaveSmtp = 'AdminSaveSmtp';
export const keyAdminDeleteSmtp = 'AdminDeleteSmtp';
export const keyAWSSESIdentities = 'AWSSESIdentities';
export const keyAWSSESIdentity = 'AWSSESIdentity';
export const keyCreateAWSSESIdentity = 'CreateAWSSESIdentity';
export const keyDeleteAWSSESIdentity = 'DeleteAWSSESIdentity';

// acquire errand
export const keyGetOneRelatedErrand = 'keyGetOneRelatedErrand';

// header / menu /footer
export const keyChangeHeaderTicker = 'ChangeHeaderTicker';
export const keyChangePickupNext = 'ChangePickupNext';
export const keyChangeAcceptChat = 'ChangeAcceptChat';
export const keyChatSessionsCount = 'ChatSessionsCount';
export const keyUpdateErrand = 'UpdateErrand';
export const keyUpdateMail = 'UpdateMail';
export const keyUpdateUser = 'UpdateUser';
export const keyAgentCurrentStatus = 'AgentCurrentStatus';
export const keyAgentGetSelectedStatus = 'AgentGetSelectedStatus';
export const keyAgentLastSeen = 'AgentLastSeen';
export const keyAgentPing = 'AgentPing';
export const keyAgentLoggedIn = 'AgentLoggedIn';
export const keyGetAgentChatSourceConfig = 'keyGetAgentChatSourceConfig';
export const keyUpdateAgentChatSourceConfig = 'keyUpdateAgentChatSourceConfig';
export const keyUpdateAgentAventaLogin = 'keyUpdateAgentAventaLogin';
export const keyAgentFavourite = 'keyAgentFavourite';

//exports
export const keyExportList = 'ExportList';
export const keySimpleTagListByArea = 'TagList';
export const keyCreateExport = 'CreateExport';
export const keyUpdateExport = 'UpdateExport';
export const keyRemoveExport = 'RemoveExport';
export const keyActivateExport = 'ActivateExport';
export const keyGeneratedExport = 'GeneratedExport';
export const keyRemoveGeneratedExport = 'RemoveGeneratedExport';
export const keyGeneratedPreview = 'GeneratedPreview';
export const keyExportCallList = 'ExportCallList';
export const keyExportCallSave = 'ExportCallSave';
export const keyExportCallRemove = 'ExportCallRemove';
export const keyExportCallActivate = `ExportCallActivate`;
export const keyGeneratedCdrExport = 'GeneratedCdrExport';
export const keyRemoveGeneratedCdrExport = 'RemoveGeneratedCdrExport';

// statistics
export const keyAdminLeaderboard = 'AdminLeaderboard';
export const keyAdminOneReports = 'AdminOneReports';
export const keyAdminPinReports = 'AdminPinReports';
export const keyAdminReports = 'AdminReports';
export const keyAdminReportDrilldown = 'AdminReportDrilldown';
export const keyAdminReportsExport = 'keyAdminReportsExport';
export const keyCreateAdminPinReports = 'CreateAdminPinReports';
export const keyDeleteOneAdminPinReports = 'DeleteOneAdminPinReports';
export const keyGetReportsOverviewLayout = 'GetReportsOverviewLayout';
export const keyGroups = 'Groups';
export const keyOneAdminPinReports = 'OneAdminPinReports';
export const keySaveReportsOverviewLayout = 'SaveReportsOverviewLayout';
export const keyTimeTimespan = 'TimeTimespan';
export const keyTimeformat = 'Timeformat';
export const keyTimeTimezones = 'TimeTimezones';
export const keyUpdateOneAdminPinReports = 'UpdateOneAdminPinReports';
export const keyCreateScheduleReport = 'CreateScheduleReport';
export const keyShowScheduledReports = 'ShowScheduledReports';
export const keyShareReportDone = 'ShareReportDone';
export const keyGetSavedReport = 'SavedReport';
export const keyAdminCustomReports = 'AdminCustomReports';
export const keyGetReportKeys = 'ReportKeys';
export const keyGetReportGroups = 'ReportGroups';
export const keyReportPreview = 'ReportPreview';
export const keyCustomTimeSpan = 'CustomTimeSpan';
export const keyTimespanFormats = 'TimespanFormats';

// gdpr
export const keyAnonymize = 'Anonymize';
export const keyDataExportLog = 'DataExportLog';
export const keyDeleteExportContact = 'DeleteExportContact';
export const keyExportContact = 'ExportContact';

//Internal Messages
export const keyGetInternalMessage = 'keyGetInternalMessage';
export const fetchInternalMessageList = 'fetchInternalMessageList';
export const keyAddEntryToAgentCard = 'keyAddEntryToAgentCard';
export const keyGetAgentCardEntry =  'keyGetAgentCardEntry';
export const keyGetAgentBook = 'keyGetAgentBook';
export const keyGetGroupBook = 'keyGetGroupBook';
export const keySendIMAnswer = 'keySendIMAnswer';
export const keyIMCounter = 'keyIMCounter';
export const keyReloadOneMessage = 'keyReloadOneMessage';
export const keyGetOneRelatedMessage = 'keyGetOneRelatedMessage';
export const keyDeleteIMs = 'keyDeleteIMs';
export const keyUpdateRead = 'keyUpdateRead';
export const keySaveIMDraft = 'keySaveIMDraft';
export const keyRestoreIMs = 'keyRestoreIMs';
export const keyFetchMsgHistory = 'FetchMsgHistory';

// Membershipd
export const keyHasMembership = "HasMembership";

// admin agent
export const keyAgentAdmin = "keyAgentAdmin";
export const keyDeleteAdminAgentsAccessKeys = 'DeleteAdminAgentsAccessKeys';
export const keyEditAgentData = "keyEditAgentData";
export const keyGetAdminAgentsAccessKeys = 'GetAdminAgentsAccessKeys';
export const keyAgentAdminList = "keyAgentAdminList";
export const keyAgentNew = "keyAgentNew";
export const keyAgentAdminSalutations = "keyAgentAdminSalutations";
export const keyAgentAdminSignatures = "keyAgentAdminSignatures";
export const keyAddAgentMP = "keyAddAgentMP";
export const keyRemoveAgentMP = "keyRemoveAgentMP";
export const keySaveAdminAgentsAccessKeys = 'SaveAdminAgentsAccessKeys';
export const keyAddAgentAvatar = "keyAddAgentAvatar";
export const keyRemoveAgentAvatar = "keyRemoveAgentAvatar";
export const keyGetAgentValidateExternalID = "keyGetAgentValidateExternalID";
export const keyCheckEECanSendExternalID = "keyCheckEECanSendExternalID";
export const keyAgentActivate = "keyAgentActivate";
export const keyAgentDeactivate = "keyAgentDeactivate";
export const keyAgentUploadList = "keyAgentUploadList";
export const keyAutoTagUploadList = "keyAutoTagUploadList";
export const keyAgentImportStatus = "keyAgentImportStatus";
export const keyAgentValidate = "keyAgentValidate";
export const keyAgentUnlock="keyAgentUnlock";
export const keyAgentStatusList = "keyAgentStatusList";
export const keyAgentSipStatus = 'keyAgentSipStatus';
export const keyFetchAgentSipStatus = 'keyFetchAgentSipStatus';
export const keyUpdateAgentSipStatus = 'keyUpdateAgentSipStatus';
export const keyValidateAgentSipStatus = 'keyValidateAgentSipStatus';
export const keyUpdateStunTurnStatus = 'keyUpdateStunTurnStatus';
export const keyAgentEmailAvailability = 'keyAgentEmailAvailability';

// admin others
export const keyAgentWordlist = "keyAgentWordlist";
export const keyEditAgentWordlist = "keyEditAgentWordlist";
export const keySaveAgentWordlist = "keySaveAgentWordlist";
export const keyDeleteAgentWordlist = "keyDeleteAgentWordlist";
export const keyGetSignatures = "keyGetSignatures";
export const keyGetSalutations = "keyGetSalutations";
export const keyGetOneSignature = "keyGetOneSignature";
export const keyGetOneSalutation = "keyGetOneSalutation";
export const keySaveSignature = "keySaveSignature";
export const keySaveSalutation = "keySaveSalutation";
export const keyDeleteSignature = "keyDeleteSignature";
export const keyDeleteSalutation = "keyDeleteSalutation";
export const keyGetAddressbook = "keyGetAddressbook";
export const keyEditAddressbook = "keyEditAddressbook";
export const keySaveAddressbook = "keySaveAddressbook";
export const keyDeleteAddressbook = "keyDeleteAddressbook";
export const keyUploadAddressbook = "keyUploadAddressbook";
export const keyGetErrandInternalState = "keyGetErrandInternalState";
export const keySaveErrandInternalState = "keySaveErrandInternalState";
export const keyCollaboratorList = 'keyCollaboratorList';
export const keyGetExternalExpertAddressList = 'keyGetExternalExpertAddressList';
export const keySaveExternalExpertAddressList = 'keySaveExternalExpertAddressList';
export const keyRemoveExternalExpertAddressList = 'keyRemoveExternalExpertAddressList';
export const keyRemoveAvatarEEAddressList = 'keyRemoveAvatarEEAddressList';
export const keyGetRouteKeywords = "keyGetRouteKeywords";
export const keyDeleteRouteKeywords = "keyDeleteRouteKeywords";
export const keyGetOneRouteKeywords = "keyGetOneRouteKeywords";
export const keySaveRouteKeywords = 'keySaveRouteKeywords';
export const keyGetRoutingGroups = "keyGetRoutingGroups";
export const keyGetOneRoutingGroups = "keyGetOneRoutingGroups";
export const keySaveRoutingGroups = 'keySaveRoutingGroups';
export const keyDeleteRoutingGroups = "keyDeleteRoutingGroups";
export const keyGetAdminGroups = "keyGetAdminGroups";
export const keyGetOneAdminGroups = "keyGetOneAdminGroups";
export const keySaveAdminGroups = "keySaveAdminGroups";
export const keyDeleteAdminGroups = "keyDeleteAdminGroups";
export const keyGetGroupFolders = "keyGetGroupFolders";
export const keyGetOneGroupFolders = "keyGetOneGroupFolders";
export const keySaveGroupFolders = "keySaveGroupFolders";
export const keyDeleteGroupFolders = "keyDeleteGroupFolders";
export const keyGetGroupAgents = "keyGetGroupAgents";
export const keyGetOneGroupAgents = "keyGetOneGroupAgents";
export const keySaveGroupAgents = "keySaveGroupAgents";
export const keyDeleteGroupAgents = "keyDeleteGroupAgents";
export const keyGetCORSWhitelist = "keyGetCORSWhitelist";
export const keyGetOneCORSWhitelist = "keyGetOneCORSWhitelist";
export const keySaveCORSWhitelist = "keySaveCORSWhitelist";
export const keyDeleteCORSWhitelist = "keyDeleteCORSWhitelist";
export const keyGetChatIPAddressBlacklist = "keyGetChatIPAddressBlacklist";
export const keyDeleteChatIPAddressBlacklist = "keyDeleteChatIPAddressBlacklist";
export const keyGetCallRecordings = "keyGetCallRecordings";
export const keyGetOneCallRecordings = "keyGetOneCallRecordings";
export const keyDownloadCallRecording = "keyDownloadCallRecording";
export const keySaveCallRecordings = 'keySaveCallRecordings';
export const keyDeleteCallRecordings = "keyDeleteCallRecordings";
export const keyGetAgentSipLogins = "keyGetAgentSipLogins";
export const keyGetOneAgentSipLogin = "keyGetOneAgentSipLogin";
export const keySaveAgentSipLogin = "keySaveAgentSipLogin";
export const keyDeleteAgentSipLogin = "keyDeleteAgentSipLogin";
export const keyGetSipPriorityNumbers = "keyGetSipPriorityNumbers";
export const keyGetOneSipPrioNumber = "keyGetOneSipPrioNumber";
export const keySaveSipPrioNumber = "keySaveSipPrioNumber";
export const keyDeleteSipPrioNumber = "keyDeleteSipPrioNumber";
export const keyGetContactCard = "keyGetContactCard";
export const keyDeleteContactCard = "keyDeleteContactCard";
export const keyGetOneContactCard = "keyGetOneContactCard";
export const keyRemoveContactCardAccount = "keyRemoveContactCardAccount";
export const keyGetContactCardNotes = "keyGetContactCardNotes";
export const keyDeleteContactCardNotes = "keyDeleteContactCardNotes";
export const keySaveContactCard = 'keySaveContactCard';
export const keyMergeContactCard = 'keyMergeContactCard';
export const keyGetRouteAutoTags = "keyGetRouteAutoTags";
export const keyGetOneRouteAutoTags = "keyGetOneRouteAutoTags";
export const keyDeleteRouteAutoTags = "keyDeleteRouteAutoTags";
export const keyGetRouteSip = "keyGetRouteSip";
export const keySaveRouteSip = 'keySaveRouteSip';
export const keyDeleteRouteSip = 'keyDeleteRouteSip';
export const keyGetOneRouteSip = "keyGetOneRouteSip";
export const keyGetFileArchive = "keyGetFileArchive";
export const keyEditFileArchive = "keyEditFileArchive";
export const keySaveFileArchive = "keySaveFileArchive";
export const keyDeleteFileArchive = "keyDeleteFileArchive";
export const keyGetOneFileArchive = "keyGetOneFileArchive";
export const keyGetCompanyList = "keyGetCompanyList";
export const keyGetConfigChangeLog = "keyGetConfigChangeLog";
export const keyAddNewCompany = "keyAddNewCompany";
export const keyRemoveCompany = "keyRemoveCompany";
export const keyGetStunTurnList = "keyGetStunTurnList";
export const keyAddStunTurn = "keyAddStunTurn";
export const keyAddNewChatWidgetCfg = "keyAddNewChatWidgetCfg";
export const keyGetChatWidgetCfgList = "keyGetChatWidgetCfgList";
export const keyGetChatWidgetCfgDefList = "keyGetChatWidgetCfgDefList";
export const keyGetFaqWidgetCfgList = "keyGetFaqWidgetCfgList";
export const keyGetFaqWidgetCfgDefList = "keyGetFaqWidgetCfgDefList";
export const keyGetVoiceWidgetCfgList = "keyGetVoiceWidgetCfgList";
export const keyGetVoiceWidgetCfgDefList = "keyGetVoiceWidgetCfgDefList";
export const keyClassifier = "keyClassifier";
export const keyFetchClassifier = "keyFetchClassifier";
export const keyUpdateClassifier = "keyUpdateClassifier";
export const keyGetTags = "keyGetTags";
export const keyGetTagsList = "keyGetTagsList";
export const keyRemoveTags = "keyRemoveTags";
export const keySaveAdminTag = "keySaveAdminTag";
export const keyAppendAdminTag = "keyAppendAdminTag";
export const keyUploadTags = "keyUploadTags";
export const keyGetOrganisations = "ketGetOrganisations";
export const keyGetOrganisationById = "keyGetOrganisationById";
export const keySaveOrganisationExternalSetting = "keySaveOrganisationExternal";
export const keyDeleteOrganisationExternalSetting = "keyDeleteOrganisationExternalSetting";
export const keyGetCallbackAPI = 'keyGetCallbackAPI';
export const keyGetCallbackAPIList = 'keyGetCallbackAPIList';
export const keySaveCallbackAPI = 'keySaveCallbackAPI';
export const keyUpdateCallbackAPI = 'keyUpdateCallbackAPI';
export const keyRemoveCallbackAPI = 'keyRemoveCallbackAPI';
export const keyGetJWTList = 'keyGetJWTList';
export const keySaveJWTAPI = 'keySaveJWTAPI';
export const keyRemoveJWTAPI = 'keyRemoveJWTAPI';
export const keyCallIVRList = 'keyCallIVRList';
export const keyCallIVRSave = 'keyCallIVRSave';
export const keyCallIVRDelete = 'keyCallIVRDelete';
export const keyCallIVRPromptUpload = 'keyCallIVRPromptUpload';
export const keyCallIVRMOHUpload = 'keyCallIVRMOHUpload';
export const keyCallIVRDeploy = 'keyCallIVRDeploy';
export const keyCallSipTrunkList = 'keyCallSipTrunkList';
export const keyGetOneSipTrunk = 'keyGetOneSipTrunk';
export const keySaveSipTrunk = 'keySaveSipTrunk';
export const keyDeleteSipTrunk = 'keyDeleteSipTrunk';

// admin accounts
export const keyGetAccountEmail = "keyGetAccountEmail";
export const keyGetOneAccountEmail = "keyGetOneAccountEmail";
export const keySaveAccountEmail = "keySaveAccountEmail";
export const keyDeleteAccountEmail = "keyDeleteAccountEmail";
export const keyGetAccountLine = "keyGetAccountLine";
export const keyGetOneAccountLine = "keyGetOneAccountLine";
export const keySaveAccountLine = "keySaveAccountLine";
export const keyDeleteAccountLine = "keyDeleteAccountLine";
export const keyGetAccountFacebook = "keyGetAccountFacebook";
export const keyGetOneAccountFacebook = "keyGetOneAccountFacebook";
export const keySaveAccountFacebook = "keySaveAccountFacebook";
export const keyDeleteAccountFacebook = "keyDeleteAccountFacebook";
export const keyGetAccountInstagram = "keyGetAccountInstagram";
export const keyGetOneAccountInstagram = "keyGetOneAccountInstagram";
export const keySaveAccountInstagram = "keySaveAccountInstagram";
export const keyDeleteAccountInstagram = "keyDeleteAccountInstagram";
export const keyGetAccountTwitter = "keyGetAccountTwitter";
export const keyGetOneAccountTwitter = "keyGetOneAccountTwitter";
export const keySaveAccountTwitter = "keySaveAccountTwitter";
export const keyDeleteAccountTwitter = "keyDeleteAccountTwitter";
export const keyGetAccountLinkedIn = "keyGetAccountLinkedIn";
export const keyGetOneAccountLinkedIn = "keyGetOneAccountLinkedIn";
export const keySaveAccountLinkedIn = "keySaveAccountLinkedIn";
export const keyDeleteAccountLinkedIn = "keyDeleteAccountLinkedIn";
export const keyGetAccountJira = "keyGetAccountJira";
export const keyGetOneAccountJira = "keyGetOneAccountJira";
export const keySaveAccountJira = "keySaveAccountJira";
export const keyDeleteAccountJira = "keyDeleteAccountJira";
export const keyGetAccountHubSpot = "keyGetAccountHubSpot";
export const keyGetOneAccountHubSpot = "keyGetOneAccountHubSpot";
export const keySaveAccountHubSpot = "keySaveAccountHubSpot";
export const keyDeleteAccountHubSpot = "keyDeleteAccountHubSpot";
export const keyGetAccountMSTeams = "keyGetAccountMSTeams";
export const keyGetOneAccountMSTeams = "keyGetOneAccountMSTeams";
export const keySaveAccountMSTeams = "keySaveAccountMSTeams";
export const keyDeleteAccountMSTeams = "keyDeleteAccountMSTeams";
export const keyGetAccountYoutube = "keyGetAccountYoutube";
export const keyGetOneAccountYoutube = "keyGetOneAccountYoutube";
export const keySaveAccountYoutube = "keySaveAccountYoutube";
export const keyDeleteAccountYoutube = "keyDeleteAccountYoutube";
export const keyGetAccountGoogleReview = "keyGetAccountGoogleReview";
export const keyGetOneAccountGoogleReview = "keyGetOneAccountGoogleReview";
export const keySaveAccountGoogleReview = "keySaveAccountGoogleReview";
export const keyDeleteAccountGoogleReview = "keyDeleteAccountGoogleReview";
export const keyGetAccountGoogleChat = "keyGetAccountGoogleChat";
export const keyGetOneAccountGoogleChat = "keyGetOneAccountGoogleChat";
export const keySaveAccountGoogleChat = "keySaveAccountGoogleChat";
export const keyDeleteAccountGoogleChat = "keyDeleteAccountGoogleChat";
export const keyGetAccountGooglePlay = "keyGetAccountGooglePlay";
export const keyGetOneAccountGooglePlay = "keyGetOneAccountGooglePlay";
export const keySaveAccountGooglePlay = "keySaveAccountGooglePlay";
export const keyDeleteAccountGooglePlay = "keyDeleteAccountGooglePlay";
export const keyGetAccountTrustpilot = "keyGetAccountTrustpilot";
export const keyGetOneAccountTrustpilot = "keyGetOneAccountTrustpilot";
export const keySaveAccountTrustpilot = "keySaveAccountTrustpilot";
export const keyDeleteAccountTrustpilot = "keyDeleteAccountTrustpilot";
export const keyGetAccountTwilio = "keyGetAccountTwilio";
export const keyGetOneAccountTwilio = "keyGetOneAccountTwilio";
export const keySaveAccountTwilio = "keySaveAccountTwilio";
export const keyDeleteAccountTwilio = "keyDeleteAccountTwilio";
export const keyGetAccountViber = "keyGetAccountViber";
export const keyGetOneAccountViber = "keyGetOneAccountViber";
export const keySaveAccountViber = "keySaveAccountViber";
export const keyDeleteAccountViber = "keyDeleteAccountViber";
export const keyGetAccountSlack = "keyGetAccountSlack";
export const keyGetOneAccountSlack = "keyGetOneAccountSlack";
export const keySaveAccountSlack = "keySaveAccountSlack";
export const keyDeleteAccountSlack = "keyDeleteAccountSlack";
export const keyGetAccountSMPP =  "keyGetAccountSMPP";
export const keyGetOneAccountSMPP = "keyGetOneAccountSMPP";
export const keySaveAccountSMPP = "keySaveAccountSMPP";
export const keyDeleteAccountSMPP = "keyDeleteAccountSMPP";
export const keyGetAccountTelegram =  "keyGetAccountTelegram";
export const keyGetOneAccountTelegram = "keyGetOneAccountTelegram";
export const keySaveAccountTelegram = "keySaveAccountTelegram";
export const keyDeleteAccountTelegram = "keyDeleteAccountTelegram";
export const keyGetAccountWhatsApp =  "keyGetAccountWhatsApp";
export const keyGetOneAccountWhatsApp = "keyGetOneAccountWhatsApp";
export const keySaveAccountWhatsApp = "keySaveAccountWhatsApp";
export const keyDeleteAccountWhatsApp = "keyDeleteAccountWhatsApp";

// admin review addresses
export const keyDeleteReviewAddress = 'DeleteReviewAddress';
export const keyGetOneReviewAddress = 'GetOneReviewAddress';
export const keyGetReviewAddresses = 'GetReviewAddresses';
export const keySaveReviewAddress = 'SaveReviewAddress';
export const keyUploadReviewAddressFile = 'UploadReviewAddressFile';
export const keySaveAgentForReview = 'SaveAgentForReview';
export const keyGetReviewAgents = 'GetReviewAgents';
export const keyDeleteReviewAgents = 'DeleteReviewAgent';

// admin template
export const keyDeleteTemplate = 'TemplateDelete';
export const keyGetOneTemplate = 'TemplateEdit';
export const keyGetTemplates = 'Templates';
export const keySaveTemplate = 'TemplateSave';

// admin Quick reply
export const keyDeleteQuickReply = 'QuickReplyDelete';
export const keyGetOneQuickReply = 'QuickReplyEdit';
export const keyGetQuickReplies = 'QuickReplies';
export const keySaveQuickReply = 'QuickReplySave';

// knowledgebase
export const keyGetAdminLibrary = 'GetAdminLibrary';
export const keyGetAdminLibraryCategory = 'GetAdminLibraryCategory';
export const keyGetAdminLibraryQuestion = 'GetAdminLibraryQuestion';
export const keyGetAdminLibraryRating = 'GetAdminLibraryRating';
export const keyGetAdminLibrarySuggestion = 'GetAdminLibrarySuggestion';
export const keyGetKnowledgeBaseRoot = 'GetKnowledgeBaseRoot';
export const keyInsertKnowledgeBaseNode = 'InserKnowledgeBaseNode';
export const keyRemoveAdminLibrary = 'RemoveAdminLibrary';
export const keyRemoveAdminLibraryCategory = 'RemoveAdminLibraryCategory';
export const keyRemoveAdminLibraryQuestion = 'RemoveAdminLibraryQuestion';
export const keyRemoveAdminLibraryRating = 'RemoveAdminLibraryRating';
export const keyRemoveAdminLibrarySuggestion = 'RemoveAdminLibrarySuggestion';
export const keyRemoveKnowledgeBaseNode = 'RemoveKnowledgeBaseNode';
export const keySaveAdminLibrary = 'SaveAdminLibrary';
export const keySaveAdminLibraryCategory = 'SaveAdminLibraryCategory';
export const keySaveAdminLibraryQuestion = 'SaveAdminLibraryQuestion';
export const keySaveAdminLibrarySuggestion = 'SaveAdminLibrarySuggestion';
export const keyFetchLibraryList = 'FetchLibraryList';
export const keyFetchCategoryList = 'FetchCategoryList';
export const keyFetchQuestionList = 'FetchQuestionList';
export const keyFetchSuggestionList = 'FetchSuggestionList';
export const keyFetchRatingList = 'FetchRatingList';
export const keyFetchMoreLibraryList = 'FetchMoreLibraryList';
export const keyFetchMoreCategoryList = 'FetchMoreCategoryList';
export const keyFetchMoreQuestionList = 'FetchMoreQuestionList';
export const keyFetchMoreSuggestionList = 'FetchMoreSuggestionList';
export const keyFetchMoreRatingList = 'FetchMoreRatingList';

// User Vote
export const keyFetchUserVote = 'keyFetchUserVote';
export const keyPostUserVote = 'keyPostUserVote';
//AI answer
export const keyGenAIAnswer = 'keyGenAIAnswer';
export const keyCreateManualErrand = 'keyCreateManualErrand';

// User launchpad
export const keyFetchForwardedErrands = "keyFetchForwardedErrands";
export const keyFetchIncomingCollaborations = "keyFetchIncomingCollaborations";
export const keyFetchDueErrandsInAWeek = "keyFetchDueErrandsInAWeek";
export const keyFetchExpiringErrands = "keyFetchExpiringErrands";
export const keyDeleteLaunchpadWidgets = "keyDeleteLaunchpadWidgets";
export const keyAddLaunchpadWidgets = "keyAddLaunchpadWidgets";
//TwoFA
export const keyTwoFANewSecret = 'TwoFANewSecret';
export const keyVerifyTwoFAToken = 'VerifyTwoFAToken';

//Skills
export const keySaveSkills = 'SaveSkills';
export const keyGetSkills = 'GetSkills';
export const keyDeleteSkills = 'DeleteSkills';
export const keySaveSkillsCategory = 'SaveSkillsCategory';
export const keyGetSkillsCategory = 'GetSkillsCategory';
export const keyGetSkillProficiency = 'GetSkillProficiency';
export const keyDeleteSkillsCategory = 'DeleteSkillsCategory';
export const keyGetSkillAgents = 'GetSkillAgents';
export const keyGetSkillAreas = 'GetSkillAreas';

//Agent Assist
export const keyAgentAssist = 'AgentAssist';

// generative AI
export const keyGetGenerativeAIDocument = "keyGetGenerativeAIDocument";
export const keyPostGenerativeAIDocument = "keyPostGenerativeAIDocument";
export const keyDeleteGenerativeAIDocument = "keyDeleteGenerativeAIDocument";
export const keyLanguageList = "keyLanguageList";

// queued errand
export const keyQueueBasicErrandInfo = 'keyQueueBasicErrandInfo';
